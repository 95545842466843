<template>
  <div class="container-frame">
    <iframe ref="frameRef"    allow="microphone *;camera *;midi *;encrypted-media *; display-capture *;"
            class="frame-box" :src="urlStr" frameborder="0" width="100%"   scrolling="no"></iframe>
  </div>
</template>

<script>
export default {
  name: "bjyLivePlayBack",
  data(){
    return{
      urlStr:'',
      contentId:''
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.$refs.frameRef.style.height = `${window.innerHeight-64}px`
      this.getUrlData()
    })
  },
  methods:{
    getUrlData(){
      this.$api.live.getBjyBackUrl({liveCourseId: this.$route.query.contentId}).then(async (resB) => {
        if(resB.data.backStatus==2){ // 百家云
          this.urlStr = resB.data.url
        } else if (resB.data.backStatus == 1) {
          this.$message.warning('视频转码中...')
          this.$router.go(-1)
        }
      });
    }
  }
}
</script>

<style scoped>
.container-frame {
  height: 80vh;
}
</style>


