import {
    emojiUrl,
    emojiMap
} from "./emoji";

export function renderDom(payload) {
    let renderDom = []
    let temp = payload.msg;
    let left = -1
    let right = -1
    while (temp !== '') {
        left = temp.indexOf('[')
        right = temp.indexOf(']')
        switch (left) {
            case 0:
                if (right === -1) {
                    renderDom.push({
                        name: 'text',
                        text: temp
                    })
                    temp = ''
                } else {
                    let _emoji = temp.slice(0, right + 1)
                    if (emojiMap[_emoji]) { // 如果您需要渲染表情包，需要进行匹配您对应[呲牙]的表情包地址
                        renderDom.push({
                            name: 'img',
                            src: emojiUrl + emojiMap[_emoji]
                        })
                        temp = temp.substring(right + 1)
                    } else {
                        renderDom.push({
                            name: 'text',
                            text: '['
                        })
                        temp = temp.slice(1)
                    }
                }
                break
            case -1:
                renderDom.push({
                    name: 'text',
                    text: temp
                })
                temp = ''
                break
            default:
                renderDom.push({
                    name: 'text',
                    text: temp.slice(0, left)
                })
                temp = temp.substring(left)
                break
        }
    }
    return renderDom
}