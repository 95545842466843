<template>
  <div class="liveing">
    <header>
      <div class="head"  ref="headRef" >
          <img src="../asset/back.png" class="back fl" @click="dialogVisible = true" alt="">
        <!--<div class="liveLogo liveLogoStudent fl"  :class="{ customLogoStyle:  logoImg.length > 0}"  v-if="logoImg" :style="{backgroundImage: 'url(' + logoImg + ')'}"></div>-->
        <!--div class="liveLogo liveLogoStudent fl" v-else></div>-->
        <div class="studentLiveTitle fl">{{this.title}}</div>
      </div>
    </header>
    <div class="liveCont" :style="{ height: liveContHeight}" ref="liveContRef">
      
        <div class="liveContLeft">
          <div class="playerBox">
            <div class="shareScreenCont">
                <img :src="roomInfor.warmPic" class="warmPic" v-if="livingState == 'nostart'" alt="">
                <div class="shareScreenContInfor">
                    <p class="noStartLiveTips" v-if="livingState == 'nostart'">直播还未开始，请耐心等候</p>
                    <p class="noShareLiveTips" v-if="livingState == 'living'">主讲未开启屏幕共享</p>
                    <p class="endLiveTips" v-if="livingState == 'finish'">直播已结束，休息一下吧</p>
                </div>
                <div class="realShareScreenBox" v-show="!whiteboard" ref="screen"></div>
                <div class="realShareScreenBox" v-show="whiteboard" ref="whiteboard"></div>
                <div class="studentCode" v-show="studentCodeShow && livingState != 'finish'">
                    <span class="fangluping">{{studentCode + '正在观看视频'}}</span>
                </div>
                <div class="largeCamera" ref="largeCamera"></div>
                <div class="charts" v-show="barrageSwitch" ref="charts"></div>
                <div class="button">
                    <el-switch
                        v-model="barrageSwitch"
                        active-color="#316FFF"
                        inactive-color="#828282"
                        inactive-text="弹"
                        @change='changebarrage'
                    >
                    </el-switch>

                    <!-- <img src="../asset/magnify-icon.png" class="magnify-icon" @click="clickmagnify" alt=""> -->
                </div>
            </div>
<!--            <div class="shareScreenCont" v-show="whiteboard">-->
<!--              <div class="realShareScreenBox" ref="whiteboard"></div>-->
<!--              <div class="largeCamera" ref="largeCamera1"></div>-->
<!--            </div>-->
          </div>
        </div>

        <div class="liveContRight">
          <!-- <div class="backgroundimg"></div> -->
          <div  class="mainSpeakerVideoStyle">
              <div class="mainSpeakerVideo">
                <div class="mainSpeakerVideoNormal">
                  <p class="noOpenCamare">主讲暂未开启摄像头</p>
                </div>
              </div>
             <div class="realyPlayerBox" ref="video" v-show="isShowOpenCamaraFlag"></div>
          </div>
          <!-- <div class="lianmaiCont" v-if="formTeacherLianmaiFlag">
              <div class="lianmaiBox">
                <p class="lianmaiStudentName">正在和讲师连麦中</p>
                <p class="lianmaiTime"></p>
                <p class="offPhone" @click="hangUp()">挂断</p>
              </div>
          </div> -->
          <div class="send-barrage" >
              <div class="title">
                <p>讨论</p>
              </div>
              <div class="middle" ref="chatContainerRef">
                <div class="Banned" v-if="bannedPost">
                  <img src="../asset/icon-jinyan.png" class="icon" alt="">
                  <p class="text">全员禁言中</p>
                </div>
                <div class="item" v-else :class="subUserInfo.id == item.userId ? 'oneself':''" v-for="(item,index) in discussionList" :key="index">
                  <div class="left head-portrait">
                    <img :src="item.userHeadPortrait ? item.userHeadPortrait : require('../../../assets/images/common/userL.png')" alt="">
                  </div>
                  <div class="right">
                    <div class="top">
                      <span class="label student" v-if="item.roleId">学生</span>
                      <span class="label teacher" v-else>老师</span>
                      <span class="name">{{item.userName}}</span>
                    </div>
                    <p class="content">
                        <span v-for="(msg,index2) in item.renderDom" :key="index2" >
                            <span v-if="msg.name === 'text'" class="text">{{msg.text}}</span>
                            <img :src="msg.src" class="emoji" v-else alt="">
                        </span>
                    </p>
                  </div>
                </div>

              </div>
              <div class="emojiMap" ref="emojiMap" v-show="emojiMapShow">
                <div class="emoji" v-for="(value,key,index) in emojiMap" :key="index" @click="getEmoji(key)">
                  <img :src="value" alt="">
                </div>
              </div>
              <div class="bottom">
                <div class="icon-box">
                  <img src="../asset/emoji-icon.png" @click="emojiMapShow = !emojiMapShow" v-clickoutside.prevent="eventHandler" class="emoji-icon" alt="">
                  <span v-if="formTeacherLianmaiFlag">
                      <img src="../asset/lianmai.png" class="lianmai icon" @click="voiceHandle('close')" v-show="audioState === 'open'" alt="">
                      <img src="../asset/jinmai.png" class="jinmai icon" @click="voiceHandle('open')" v-show="audioState === 'close'" alt="">
                  </span>
                  <circleLoad></circleLoad>
                </div>
                <div class="input-box">
                  <el-input placeholder="请输入..." resize="none" type="textarea" id="emojiInput" maxlength="70" show-word-limit @keyup.enter.native.prevent="sendBarrage" v-model="barrageText"></el-input>
                  <el-button type="primary" size="small" @click="sendBarrage" :style="bannedPost?'background:#ccc;border-color:#ccc':'background:#316FFF;bborder-color:#316FFF'" round>发送</el-button>
                </div>
              </div>
          </div>
        </div>



        <div class="" ref="audio"></div>
    </div>
    <el-dialog
        title="确定退出直播吗？"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <span>退出直播后，会错过老师精彩讲解哟</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="goBack">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 弹窗引导用户点击屏幕   触发本地音频重启 -->
    <el-dialog
        title="提示"
        :visible.sync="guideDialogVisible"
        width="30%"
        center>
        <span>没有听到声音点击一下页面, 再试一试</span>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="guideDialogVisible = false">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
// import { openDeviceCheckDialog } from './dialogs/check/DeviceCheckDialog';
import { client as cameraReceiver } from '../libs/receive'
import { imclient } from '../libs/im_client';
import { barrage } from '../libs/chats'
import circleLoad from './time' // 举手

import {emojiMap} from '../libs/emoji'
import { renderDom } from '../libs/emojiRender'
import clickoutside from '@libs/clickoutside.js'
let studentUserId;
async function processReceiver(vm, cameraEl, screenEl, audioEl, whiteboardEl) {
    const data = {
        frontId:1,// "0-不区分 1-pc 2-h5",
        roomId:vm.room_id,//
        shareType:2,//"0-摄像头+音频 1-屏幕共享 2-纯音频流"
        typeId:vm.live_course_id,//"业务id(直播id/考试id)"
        type: 1
    }
    let res = await vm.$api.live.getRoomInfo({data});
    studentUserId = studentUserId || res.data.userId;

    const receiver = cameraReceiver(res.data.userId, res.data.skuAppId, res.data.userSig, vm);

    receiver.startListen(cameraEl, screenEl, audioEl, whiteboardEl);
    receiver.onLiving = () => { //    直播中 触发
      vm.getRoomIdInfor();
    };

    // 老师摄像头开启
    receiver.onVideoOpen = () => {
      vm.isShowOpenCamaraFlag = true;
    };

    // 老师摄像头关闭
    receiver.onVideoClose = () => {
      vm.isShowOpenCamaraFlag = false;
    };

    // 屏幕共享开启
    receiver.onScreening = () => {
      vm.whiteboard = false

      // 隐藏白板流展示共享屏幕流
        vm.switchBSAction(false,vm)
    };
    // 屏幕共享关闭
    receiver.onScreenFinish = () => {
      vm.whiteboard = true
        vm.switchBSAction(true,vm)

    };

    receiver.joinRoom(vm.room_id);

    vm.$once('hook:beforeDestroy', async () => {
      // debugger;
        try {
            await vm.hangUp();
            console.log('挂断电话')
        } catch (error) {
            console.error(error);
        }
        try {
          await receiver.leaveRoom();
          console.log('离开房间')
        } catch (error) {
            console.error(error);
        }

        if (sessionStorage.getItem('kicked') === 'true') {
            sessionStorage.removeItem('kicked');
        } else {
            try {
                await vm._group.quitGroup();
                console.log('退出群组')
            } catch (error) {
                console.error(error);
            }
            try {
                await vm._imClient.logout();
                console.log('im 登出')
            } catch (error) {
                console.error(error);
            }
        }

        vm.$nextTick(() => {
            location.reload();
        });
    });

  return receiver;
}


async function processImClient(vm) {
    const data = {
      groupId:vm.roomInfor.groupId,
      terminal: 1, // 判断当前学员端登录设备   1-pc 2-h5
    }
    const res = await vm.$api.live.getUsersig({data});
    studentUserId = studentUserId || res.data.imuserId;
    const client = imclient(res.data.sdkappid, studentUserId, vm);
    const cmdQueue = [];
    const barrageHander = barrage(vm.$refs.charts);
    (function execCmds() { // 连麦
        const msgObj = cmdQueue.shift();
        const msg = msgObj && JSON.parse(msgObj.msg)
        if (msg && msg.type === 14) {
            vm._replyTeacher = (success, reason) => {
              console.log('=======studentUserId', studentUserId)
                vm._group.sendMessageForGroup({
                    cmd: 1002,
                    from: studentUserId,
                    to: studentUserId,
                    // to: msgObj.from,  // 可能是写错了   msgObj.from应该是教师端的来源
                    success,
                    reason
                }).then(() => {
                    console.log('-------挂断消息已经回复-------')
                }).catch((error) => {
                    console.error(error);
                });
            };
            const message = { cmd: msgObj.cmd, from: studentUserId, to: msgObj.from };
            console.log('=======message', message)
            vm.msg = msg
            vm.answerPhone().then(() => {
                vm._group.sendMessageForGroup({
                    ...message,
                    success: true
                });

                setTimeout(execCmds);
            }).catch((error) => {
                console.error(error.message);
                vm._group.sendMessageForGroup({ ...message, success: false,  reason: error.message });
                setTimeout(execCmds);
            });
        } else if (msg && msg.type === 15) { // 断麦信号
            console.log('-------断麦信号-------')
            vm.msg = msg
            vm.hangUp(3).then(() => {
                setTimeout(execCmds);
            }).catch(() => {
                setTimeout(execCmds);
            });
        } else {
            setTimeout(execCmds, 1000);
        }
    })();

    client.onWaiting((msgObj) => { // 单聊指令
        cmdQueue.push(msgObj);
        const msg = JSON.parse(msgObj.msg)
        vm.msg = msg;
        console.log(msg,'msg--单人消息')
        if(msg.type === 10){
            vm.voiceHandle('close')
        }else if(msg.type === 11){
            vm.voiceHandle('open')
        }else if(msg.type === 15){
            vm.hangUp(3);
        }
    });

    client.onWaitingGroup(async (msgObj) => { // 群聊信息
        /***
         * action 1 弹幕  2 指令
         */
        if(msgObj.action === 1){
            vm.discussionList.push(msgObj); // 讨论区
            vm.discussionList.length && vm.discussionList.forEach(item =>{
                item.renderDom = renderDom(item)

            })
            barrageHander.emit(msgObj); // 弹幕
        }else if (msgObj.action === 2){
            const msg = JSON.parse(msgObj.msg)
          /**
           * type
           * 1 申请发言 举手 2 申请发言结果 老师是否同意举手 3 踢人
           * 全员
           * 4 全员禁言 5 全员解除禁言 6 全员禁麦 7 全员解除禁麦
           * 单人
           * 8 禁言 9 解除禁言 10 禁麦 11 解除禁麦
           * 12 点名 13 点名回掉 14 上台 15 下台 16 推有声流（状态）17 推无声流（状态）
           *
           */
          console.log(msg,'------群组消息----')
            vm.msg = msg
            if(msg.type === 4){
              vm.bannedPost = true;
            }else if(msg.type === 5){
              vm.bannedPost = false;
            }else if(msg.type === 6){
              vm.voiceHandle('close')
            }else if(msg.type === 7){
              vm.voiceHandle('open')
            }else if(msg.type === 15){ // 断麦下台
              vm.hangUp(3);
            }
        }
        if (msgObj.cmd === 1003) {
            vm.whiteboard = false;
            vm.livingState = 'finish';
            vm.hangUp();
        } else if (msgObj.cmd === 1002) { // 断麦信号
            if (msgObj.to === undefined) {
                vm._hangUp && vm._hangUp(() => {
                    vm.formTeacherLianmaiFlag = false;
                    vm.offing = false;
                    delete vm._hangUp;
                }, () => {
                    vm.offing = false;
                });
            } else {
                console.log(msgObj.to,'msgObj.to---')
                if (msgObj.to.startsWith('student-') && studentUserId != msgObj.to) {  // 不是发给当前用户的, 强制关闭对应的流
                    vm._receiver.forceCloseStream(msgObj.to);
                }
            }
        } else if (msgObj.cmd === 1004) {
            console.log('收到主播端切换视频视图+++++')
            vm.enlarge(msgObj);
        }
        //  else if (msgObj.cmd === 1005) {
        //     for (let item of msgObj.argv) {
        //         barrageHander.emit(item.barrage);
        //     }


        // }
    });


    try {
        // console.log('im 开始登录 登录成功')
        await client.loginAndReady(res.data.imuserId, res.data.userSig);
        console.log('im 登录成功');
    } catch (error) {
        console.error('im 登录失败, 无法发送连麦请求, 建议刷新一下网页重试', error)
    }

    try {
        vm._group = await client.joinGroup(vm.roomInfor.groupId);
        console.log('进入群组成功');
    } catch(error) {
        console.log('进入群组失败', error);
    }
    console.log('im 初始化')
    return client;
}

export default {
    name: 'studentSee',
    components: {
        circleLoad
    },
    data() {
        return {
            whiteboard: true,
            guideDialogVisible: false, // 引导弹窗状态
            studentCodeShow: false,
            dialogVisible: false,
            msg: null,
            bannedPost: false, // 禁言
            isbigShow: false,
            discussionList: [],
            emojiMapShow: false,
            emojiMap: emojiMap,
            barrageSwitch: true,
            roomInfor: '',
            title: '',
            livingState: '',   // 直播状态  'nostart'  'living'   'finish'
            offing: false,   // 挂断中
            oning: false,    // 连麦中
            clientHeight: '',
            camaraSwitchFlag: false,
            voiceSwitchFlag: false,
            isShowOpenCamaraFlag: false,
            formTeacherLianmaiFlag: false,
            logoImg: '',
            fromTheme: '',
            barrageText: '',
            largeMode: false,
            isScreen: null,
            subUserInfo: JSON.parse(localStorage.getItem('subUserInfo')),
            audioState: 'close',
            live_course_id: this.$route.query.roomId,
            studentCode: null, // 防录屏code
            getErrorCodeInterval: '', // 轮询获取是否存在errorCode数据  保证一定时间内可以获取到自动播放音频错误
            getErrorTime: 0, // 存储错误码轮询次数
            videoDiv: null, // 视频变量
            screenDiv: null, // 屏幕共享变量
            whiteBoardDiv: null // 白板变量
        };
    },
    directives: {
        clickoutside,
    },
    computed: {
        liveContHeight() {
            return (window.innerHeight - 51) + 'px';
        },
    },

//   beforeRouteEnter(to, from, next) {
//         console.log(from)
//         if (from.name) {
//             const dialog = openDeviceCheckDialog(to.query.roomId, (closeCallback) => {
//                 const listener = () => {
//                     closeCallback()
//                     window.removeEventListener('popstate', listener);
//                 };
//                 window.addEventListener('popstate', listener);
//             });
//             dialog.afterClosed().then(() => {
//                 next(true);
//             });
//         } else {
//             next(true);
//         }
//   },

    async created() {
        this.fromTheme = localStorage.getItem('theme')
        this.liveLogo();
    },
    updated() {
        if (this.$refs.chatContainerRef) {
            this.$refs.chatContainerRef.scrollTop = this.$refs.chatContainerRef.scrollHeight
        }
    },
    async mounted() {
        await this.getRoomIdInfor();
        await this.examineLiveCourseRole()
        await this.windowResize()
        // await this.judgeOnlineInfo();
        this.$route.query.roomId && (this._imClient = await processImClient(this)) // 解决分享链接登录后的报错问题  没有roomId
        this._receiver = await processReceiver(this, this.$refs.video, this.$refs.screen, this.$refs.audio, this.$refs.whiteboard);
        // 设置轮询请求，超过轮询次数未拿到错误码，默认音频正常
        this.getErrorCodeInterval = setInterval(() => {
            this.guideDialogVisible = this._receiver.getErrorFn()
            this.getErrorTime++
            if (this.getErrorTime > 4 || this.guideDialogVisible) {
                clearInterval(this.getErrorCodeInterval)
            }
        }, 3000);
    },

    beforeDestroy() {
        location.reload();
        //   clearTimeout(this._onlineTimmer);
    },

    methods: {
        async goBack() {
            //   try {
            //         await this.hangUp();
            //         console.log('挂断电话')
            //     } catch (error) {
            //         console.error(error);
            //     }
            //     try {
            //       await this._group.quitGroup();
            //         console.log('退出群组')
            //     } catch (error) {
            //         console.error(error);
            //     }
            try {
                await this._imClient.logout();
                console.log('im 登出')
            } catch (error) {
                console.error(error);
            }
            this.dialogVisible = false;
            this.$router.push('/learning')
            // location.reload();
        },
        handleClose() {
            this.dialogVisible = false;
        },
        eventHandler() {
            this.emojiMapShow = false;
        },
        async examineLiveCourseRole() {
            const params = {
                liveCourseId: this.live_course_id,
                role: 1
            }
            await this.$api.live.examineLiveCourseRole({params}).then(res => {
                if (!res.success) {
                    // this.$message.error(res.message);
                    if (this.$route.query.isOpenType) { // 在课程中心 video 中进入直播间 退出后回学习中心
                        this.$router.push('/learning')
                        return
                    }
                    this.$router.go(-1)
                } else {
                    this.bannedPost = res.data.isForbidden
                }
            })
        },
        // 麦克风
        voiceHandle(type) {
            if (type == 'close') {
                this.audioState = 'close'
                this._receiver.muteAudio();
                // this.$message.warning('关闭麦克风')
                this.statueCallBack(2, false)
            } else {
                this.audioState = 'open'
                // this.$message.warning('开启麦克风')
                this._receiver.unmuteAudio();
                this.statueCallBack(2, true)

            }
        },

        statueCallBack(type, state) {
            this.isUserType = type
            this.isState = state
            const data = {
                roleId: 1,
                liveCourseId: this.live_course_id,
                type: type,//1 文字，2 麦克风，3 上下台
                teacherImUid: this.msg.from,
                seqId: this.msg.seqId,
                state: state // 布尔值 true 代表麦克风开启关闭 上下台成功

            }
            this.$api.live.statueCallBack({data}).then(res => {
                console.log(res, '------告诉服务端======')
                if (type == 2 && state == false) {
                    this.$message.warning('关闭麦克风')
                }
                if (type == 2 && state == true) {
                    this.$message.warning('开启麦克风')
                }
            })
        },
        async getStudentVisitCode() {
            await this.$api.live.getStudentVisitCode({}).then(res => {
                this.studentCode = res.data
            })
        },
        clickmagnify() {
            this.isbigShow = !this.isbigShow
        },
        //   弹幕开关
        changebarrage() {
            console.log(this.barrageSwitch)
        },
        liveLogo() {
            const configurationArr = JSON.parse(localStorage.getItem('configurationArr'))
            configurationArr.filter((item) => {
                if (item.key && item.key == "custom_logo") {
                    this.logoImg = item.value
                    return false
                }
            });
        },

        answerPhone() {
            return new Promise((resolve, reject) => {
                if (this.formTeacherLianmaiFlag) {
                    resolve();
                    return;
                }
                if (this.oning) {
                    resolve();
                    return;
                }
                this.oning = true;
                this._hangUp = this._receiver.switchAnchor(() => {
                    this.statueCallBack(3, true)
                    this.statueCallBack(2, true)
                    this.audioState = 'open'
                    this.formTeacherLianmaiFlag = true;
                    this.oning = false;
                    resolve();
                }, (error) => {
                    this.oning = false;
                    reject(error)
                });
            });
        },

        hangUp() {
            console.log('断麦下台---')
            return new Promise((resolve, reject) => {
                if (this._hangUp) {
                    if (!this.formTeacherLianmaiFlag) {
                        resolve();
                        return;
                    }
                    if (this.offing) {
                        resolve();
                        return;
                    }
                    this.offing = true;   // 断开中
                    this._hangUp(() => {
                        this.statueCallBack(3, false)
                        this.statueCallBack(2, false)
                        this.audioState = 'close'
                        this.formTeacherLianmaiFlag = false;
                        this.offing = false;
                        delete this._hangUp;
                        this._replyTeacher(true);
                        resolve();
                    }, (error) => {
                        this.offing = false;
                        this._replyTeacher(false, error.message);
                        reject(error);
                    });
                } else {
                    resolve();
                }
            });
        },

        getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]);
            return null;
        },

        async getRoomIdInfor() {
            await this.$api.live.findById(
                {
                    id: this.live_course_id // 课程id
                }
            )
                .then(res => {
                    if (res.success) {
                        this.roomInfor = res.data;
                        this.room_id = res.data.roomId;
                        this.isScreen = res.data.isScreen
                        this.title = res.data.title;
                        if (this.isScreen) {
                            this.studentCodeShow = true
                            this.getStudentVisitCode()
                        }
                        //  直播状态：0、未开始 1、直播中 2、正常结束 3、异常结束 4、取消
                        const statusObj = {
                            0: 'nostart',
                            1: 'living',
                            2: 'finish',
                            3: 'finish',
                            4: 'finish'
                        }
                        this.livingState = statusObj[this.roomInfor.status];
                        if (this.roomInfor.status == 2 || this.roomInfor.status == 3) {
                            this.hangUp();
                        }
                    }
                }).catch((error) => {
                    console.log('error', error)

                })
        },

        windowResize() {
            window.onresize = () => {
                this.clientHeight = `${document.documentElement.clientHeight}`;
                if (this.$refs.liveContRef) {
                    this.$refs.liveContRef.style.height = this.clientHeight - 60 + 'px';
                }

                if (this.$refs.studentListRef) {
                    this.$refs.studentListRef.style.height = window.innerHeight - 411 + 'px';

                }
            }
        },

        // judgeOnlineInfo() {
        //     const refreshOnline = () => {
        //         this.$api.live.judgeOnlineInfo( {roomId: this.room_id,} ).then(() => {
        //             this._onlineTimmer = setTimeout(refreshOnline, 30 * 1000);
        //         }).catch(() => {
        //             this._onlineTimmer = setTimeout(refreshOnline, 30 * 1000);
        //         })
        //     }
        //     this._onlineTimmer = setTimeout(refreshOnline)
        //     this.$once('hook:beforeDestroy', async () => {
        //         clearTimeout(this._onlineTimmer);
        //     });
        // },


        // 共享屏幕和白板切换
        switchBSAction(wbStatus, vm) {
            if (!wbStatus) { // 白板关闭 共享屏幕打开
                if (this.largeMode) { //左侧是摄像头 右侧是屏幕共享
                    console.log('共享屏幕中+++++++++++++右边展示共享屏幕', (vm.$refs.screen.children[0]));
                    if (this.$refs.screen.children[0]) { // 右边展示屏幕共享
                        this.screenDiv = null;
                        this.screenDiv = this.$refs.screen.children[0];
                        if (this.$refs.video.children.length > 0) {
                            this.$refs.video.removeChild(this.$refs.video.children[0])
                        }
                        this.$refs.video.appendChild(this.screenDiv);
                    }

                    if(this.videoDiv){
                        if (this.$refs.largeCamera.children.length > 0) {
                            this.$refs.largeCamera.removeChild(this.$refs.largeCamera.children[0])
                        }
                        this.$refs.largeCamera.appendChild(this.videoDiv);
                    }

                } else { // 左侧是屏幕共享 右侧是摄像头
                    console.log('左侧是摄像头 右侧是屏幕共享++++', this.$refs.screen.children[0])
                    if (this.videoDiv) {
                        if (this.$refs.video.children.length > 0) {
                            this.$refs.video.removeChild(this.$refs.video.children[0])
                        }
                        this.$refs.video.appendChild(this.videoDiv);
                    }

                    console.log('左侧是摄像头 右侧是屏幕共享++++his.screenDiv', this.screenDiv)
                    if (this.screenDiv) {
                        console.log('99999999++++++++++++')
                        if (this.$refs.largeCamera.children.length > 0) {
                            this.$refs.largeCamera.removeChild(this.$refs.largeCamera.children[0])
                        }
                        this.$refs.largeCamera.appendChild(this.screenDiv);
                    } else {
                        console.log('共享屏幕+++开启9999999++++++')
                        if (this.$refs.screen.children[0]) {
                            this.screenDiv = null;
                            this.screenDiv = this.$refs.screen.children[0];
                            if (this.$refs.largeCamera.children.length > 0) {
                                this.$refs.largeCamera.removeChild(this.$refs.largeCamera.children[0])
                            }
                            this.$refs.largeCamera.appendChild(this.screenDiv);
                        }
                    }


                }
            } else { // 白板开启 左侧是摄像头 右侧是白板
                this.screenDiv =null;
                if (this.largeMode) { // 左侧是摄像头 右侧是白板
                    console.log('左侧是摄像头 右侧是白板++++', this.videoDiv)
                    if (this.videoDiv) {
                        if (this.$refs.largeCamera.length > 0) {
                            this.$refs.largeCamera.removeChild(this.$refs.largeCamera.children[0])
                        }
                        this.$refs.largeCamera.appendChild(this.videoDiv)
                    }
                    if (this.whiteBoardDiv) {
                        if (this.$refs.video.length > 0) {
                            this.$refs.video.removeChild(this.$refs.video.children[0])
                        }
                        this.$refs.video.appendChild(this.whiteBoardDiv);
                    }
                } else { // 左侧是白板 右侧是摄像头
                    console.log('左侧是白板 右侧是摄像头++++', this.videoDiv)
                    if (this.videoDiv) {
                        if (this.$refs.video.length > 0) {
                            this.$refs.video.removeChild(this.$refs.video.children[0])
                        }
                        this.$refs.video.appendChild(this.videoDiv)
                    }
                    if (this.whiteBoardDiv) {
                        if (this.$refs.largeCamera.length > 0) {
                            this.$refs.largeCamera.removeChild(this.$refs.largeCamera.children[0])
                        }
                        this.$refs.largeCamera.appendChild(this.whiteBoardDiv);
                    }


                }

            }

        },

        enlarge(msgObj) {
            console.log(msgObj.argv, '切换大小视图++++++++')
            if (msgObj.argv === 'large') {
                // large 摄像头变大在左侧 右侧展示共享屏幕、或者白板
                this.largeMode = true;
                if (this.$refs.video.children[0]) {
                    this.videoDiv = null;
                    this.videoDiv = this.$refs.video.children[0];
                }

                if (this.$refs.whiteboard.children[0]) {
                    this.whiteBoardDiv = null;
                    this.whiteBoardDiv = this.$refs.whiteboard.children[0];
                }

                if (this.$refs.screen.children[0]) {
                    this.screenDiv = null;
                    this.screenDiv = this.$refs.screen.children[0];
                }
                if (this.videoDiv) {
                    if (this.$refs.largeCamera.children.length > 0) {
                        this.$refs.largeCamera.removeChild(this.$refs.largeCamera.children[0])
                    }
                    this.$refs.largeCamera.appendChild(this.videoDiv)
                }
                if (this.whiteboard) {
                    if (this.$refs.video.children.length > 0) {
                        this.$refs.video.removeChild(this.$refs.video.children[0])
                    }
                    this.$refs.video.appendChild(this.whiteBoardDiv)
                } else {
                    if (this.screenDiv) {
                        if (this.$refs.video.children.length > 0) {
                            this.$refs.video.removeChild(this.$refs.video.children[0])
                        }
                        this.$refs.video.appendChild(this.screenDiv)
                    } else {
                        if (this.$refs.screen.children[0]) {
                            this.screenDiv = null
                            this.screenDiv = this.$refs.screen.children[0]
                            if (this.$refs.video.children.length > 0) {
                                this.$refs.video.removeChild(this.$refs.video.children[0])
                            }
                            this.$refs.video.appendChild(this.screenDiv)
                        }

                    }
                }
            } else if (msgObj.argv === 'small') {
                this.largeMode = false;
                // large 摄像头变小在右侧 左侧展示共享屏幕、或者白板
                if (this.whiteboard) { // 有白板
                    if (this.videoDiv) {
                        this.$refs.video.appendChild(this.videoDiv);
                    }
                    if (this.whiteBoardDiv) {
                        this.$refs.whiteboard.appendChild(this.whiteBoardDiv)
                    }
                } else { // 没有白板

                    if (this.videoDiv) {
                        this.$refs.video.appendChild(this.videoDiv);
                    }

                    if (this.screenDiv) {
                        this.$refs.screen.appendChild(this.screenDiv);
                    }
                }
            }

        },
        // 点击获取表情
        getEmoji(emoji) {
            let elInput = document.getElementById('emojiInput'); //根据id选择器选中对象
            let startPos = elInput.selectionStart;// input 第0个字符到选中的字符
            let endPos = elInput.selectionEnd;// 选中的字符到最后的字符
            if (startPos === undefined || endPos === undefined) return
            let txt = elInput.value;
            // 将表情添加到选中的光标位置
            let result = txt.substring(0, startPos) + emoji + txt.substring(endPos)
            elInput.value = result;// 赋值给input的value
            // 重新定义光标位置
            elInput.focus();
            elInput.selectionStart = startPos + emoji.length;
            elInput.selectionEnd = startPos + emoji.length;
            this.barrageText = result
        },
        sendBarrage() {
            // 空格
            if (!this.barrageText.trim().length) {
                return this.$message.info('输入内容才可以发送')
            }
            // 禁言
            if (this.bannedPost) {
                return false
            }
            this.barrageText = this.barrageText.substr(0, 70);
            let data = {
                barrage: this.barrageText,
                liveCourseId: this.live_course_id, // 课程id
                roleId: 1,// 0 教师 1 学生
            }
            this.$api.live.saveLiveBarrage({data}).then((res) => {
                if (res.success) {
                    console.log(res)
                } else {
                    // this.$message.warning('内容中存在违法法律法规的信息')
                }
            }).catch((error) => {
                console.log(error);
            });
            this.barrageText = '';
        }
    }

};
</script>

<style lang="stylus" scoped>
 @import "../asset/css/liveing.styl"
</style>
