<template>
  <div id="active" :class="{ empty: noOneSpeaking }">
    <div v-if="isTeacher" id="active-list">
      <div class="placeholder"></div>
    </div>

    <!-- 申请列表 -->
    <div v-if="isTeacher" class="bjy-apply-list">
      <div class="placeholder"></div>
    </div>

    <!-- 自己播放器 -->
    <div v-else class="live-self-player">
      <div @click="playerShow" :style="{marginBottom: isShow ? '-27px' : '0px'}" class="hide-button">{{isShow ? '隐藏' : '展示'}}</div>
      <SelfPlayer />
    </div>
  </div>
</template>

<script>
// 自己播放器
import SelfPlayer from "../SelfPlayer";
const eventEmitter = BJY.eventEmitter;
const auth = BJY.auth;

export default {
  name: "activeList",
  components: {
    SelfPlayer,
  },
  data() {
    return {
      isShow: true,
      activeList: null,
      noOneSpeaking: true,
      isTeacher: false,
      applyList: null
    };
  },
  computed: {},
  watch: {},
  methods: {
    playerShow() {
      if (this.isShow) {
        this.isShow = false
        $("#live-self-player").css('display', 'none')
      } else {
        this.isShow = true
        $("#live-self-player").css('display', 'block')
      }
    }
  },
  created() {
    this.isTeacher = auth.isTeacher();
  },
  mounted() {
    if (this.isTeacher) {
      const activeList = BJY.ActiveList.create({
        element: $("#active-list .placeholder"), // 父容器
        extension: BJY.getExtension(), // 播放器扩展
        width: 260, // 父容器宽度，用于计算播放器的高度
        replace: false, // 是否替换父容器下的 DOM
      });

      eventEmitter.on(
        eventEmitter.ACTIVE_LIST_PRESENTER_CHANGE_TRIGGER,
        function (event, data) {
          activeList.changePresenter(data.newPresenter, data.oldPresenter);
        }
      );

      activeList.watch("userList.length", (newValue, oldValue) => {
        console.log('activeList.watch ', oldValue);
        if (newValue !== 0) {
          this.noOneSpeaking = false;
        } else {
          this.noOneSpeaking = true;
        }
      });
      this.activeList = activeList
      this.applyList = BJY.ApplyList.create({
        element: $(".bjy-apply-list .placeholder"), // 组件父容器
        replace: false, // 是否替换父容器下的 DOM
      });
    }
  },
  beforeDestroy () {
    this.activeList && this.activeList.destroy()
    this.applyList && this.applyList.destroy()
  }
};
</script>

<style lang="less">
//@import url(); 引入公共css类
#active {
  .live-self-player {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 220px;
    width: 266px;
    position: absolute;
    bottom: 0;
    transition: 0.2s bottom;
  }
  & > .hide {
    bottom: -200px !important;
  }

  & > .hide .bjy-fold-bar {
    top: -24px !important;
  }

  & > .hide .bjy-fold-bar .bjy-fold-toggle {
    background: #424242 !important;
  }
}
.empty {
  height: 100%;
  width: 100%;
  background: url("../../asset/img/active-user-list-empty.png") center center no-repeat;
}
.hide-button {
  background-color: rgb(36,42,54);
  color: #fff;
  text-align: center;
  padding: 3px;
  z-index: 1;
}
</style>
