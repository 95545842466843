<template>
  <div class="live-interaction-wrapper">
    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
    name: 'Interaction',
    data() {
      return {
        instance: null
      }
    },
    mounted() {
        var auth = BJY.auth;
        // var eventEmitter = BJY.eventEmitter;
        this.instance = BJY.Interaction.create({
            element: $('.live-interaction-wrapper .placeholder'), // 父容器
            replace: true,
            // 是否有红包雨
            needRedPacket: true,
            // 是否可以抢红包
            canGetRedPacket: auth.isStudent(),
            // 是否可以发红包
            canSendRedPacket: auth.isTeacher(),
            // 红包相关配置
            redPacketOptions: {
                skin: 'bjy',
                // 是否有排行榜
                hasRankingList: true,
                // 自定义的红包图片，不设置使用默认的
                //redPacketFrame: 'https://my-redpacket.png',
                // 默认发红包个数
                defaultRedPacketCount: 100,
                // 默认红包总金额
                defaultAmountNumber: 200,
                // 默认抢红包时间
                defaultRedPacketDuration: 15,
                // 能设置的最大红包数
                maxRedpacketCount: 1000,
                // 能设置的最大红包金额
                maxRedpacketAmount: 1000,
                // 自动关闭排行榜
                autoCloseRankingList: false,
                // 红包内容术语
                resultScore: '金币'
            }

        })
    },
    beforeDestroy() {
        this.instance && this.instance.destroy()
    },
}
</script>

<style>

</style>