<template>
    <div class="content" ref="box">
    <svg style="transform: rotate(-90deg);padding-top:3px;" :width="width" :height="width" xmlns="http://www.w3.org/2000/svg">
      <circle :r="(width-radius)/2"
        :cy="width/2"
        :cx="width/2"
        :stroke-width="radius"
        :stroke="backgroundColor"
        fill="none"
      />
      <circle ref="$bar"
        :r="(width-radius)/2"
        :cy="width/2"
        :cx="width/2"
        :stroke="barColor"
        :stroke-width="radius"
        :stroke-linecap="isRound ? 'round' : 'square'"
        :stroke-dasharray="(width-radius)*3.14"
        :stroke-dashoffset="isAnimation ? (width-radius) * 3.14 : (width - radius) * 3.14 * (100 - progress) / 100"
        fill="none"
      />
      <image  href="../asset/jushou.png" x="-3" y="-2" class="hand-icon icon" alt="" style="width:25px;height:25px" @click="handClick()"></image>
    </svg>
  </div>
</template>

<script>
    export default {
        name: "circleLoad",
        data(){
            return{
                 width: this.circleWidth,
                 idStr: `circle_progress_keyframes_${this.id}`,
                 progress:0,
                 countDown:15,
                 interval:null
            }
        },
        props: {
            radius: {
                type: [Number, String],
                default: 1
            }, // 进度条厚度
            barColor: {
                type: String,
                default: "#316FFF"
            }, // 进度条颜色
            backgroundColor: {
                type: String,
                default: "#979797"
            }, // 背景颜色
            isAnimation: {
                // 是否是动画效果
                type: Boolean,
                default: true
            },
            isRound: {
            // 是否是圆形画笔
                type: Boolean,
                default: true
            },
            id: {
            // 组件的id，多组件共存时使用
                type: [String, Number],
                default: 1
            },
            duration: {
            // 整个动画时长
                type: [String, Number],
                default: 1000
            },
            delay: {
            // 延迟多久执行
                type: [String, Number],
                default: 200
            },
            timeFunction: {
            // 动画缓动函数
                type: String,
                default: "cubic-bezier(0.99, 0.01, 0.22, 0.94)"
            },
            circleWidth: {
                //圆环宽度
                type: Number,
                default: 20,
            },
            color: {
                //文字颜色
                type: String,
                default: '#000'
            },
            fontSize: {
                //文字大小
                type: String,
                default: '18px'
            }
        },
        beforeDestroy() {
            // 清除旧组件的样式标签
            document.getElementById(this.idStr) &&
            document.getElementById(this.idStr).remove();
            // window.addEventListener(() => {});
        },
        methods:{
            // 举手
            setCircleWidth() {
                let box = this.$refs.box;
                let width = box.clientWidth;
                let height = box.clientHeight;
                let cW = width > height ? height : width;
                this.width = cW;
            },
            setAnimation() {
                let self = this;
                if (self.isAnimation) {
                    // 重复定义判断
                    if (document.getElementById(self.idStr)) {
                    console.warn("vue-circle-progress should not have same id style");
                    document.getElementById(self.idStr).remove();
                    }
                    // 生成动画样式文件
                    let style = document.createElement("style");
                    style.id = self.idStr;
                    style.type = "text/css";
                    style.innerHTML = `
                @keyframes circle_progress_keyframes_name_${self.id} {
                from {stroke-dashoffset: ${(self.width - self.radius) * 3.14}px;}
                to {stroke-dashoffset: ${((self.width - self.radius) *
                    3.14 *
                    (100 - self.progress)) /
                    100}px;}}
                .circle_progress_bar${
                    self.id
                } {animation: circle_progress_keyframes_name_${self.id} ${
                    self.duration
                    }ms ${self.delay}ms ${self.timeFunction} forwards;}`;
                    // 添加新样式文件
                    document.getElementsByTagName("head")[0].appendChild(style);
                    // 往svg元素中添加动画class
                    self.$refs.$bar.classList.add(`circle_progress_bar${self.id}`);
                }
            },
            handClick(){
                let self = this;
                self.requestToSpeak()
                 this.interval = setInterval(() => {
                    this.countDown--
                    self.progress+= 100/15
                    console.log('ccccc',this.countDown,this.progress)
                    if (this.countDown <= 0) {
                        clearInterval(this.interval)
                        this.interval = null;
                        self.progress = 0;
                        this.countDown = 15;
                    }
                     self.setCircleWidth();
                     self.setAnimation();
                }, 1000)
            },
            // 举手
            requestToSpeak(){
                let data = {
                    liveCourseId: this.$route.query.roomId, // 课程id
                    roleId:1,// 0 教师 1 学生
                }
                this.$api.live.requestToSpeak({data}).then(res => {
                    console.log(res,'举手')
                })
            },
        }
    }
</script>

<style lang="stylus" scoped>
@import "../asset/css/liveing.styl"
.content {display:inline-block;}
.center_text {position:absolute}
</style>