<template>
    <div class="liveList">
        <live-list></live-list>
    </div>
</template>
<script>
import LiveList from '../../../layout/business/liveList.vue'
export default {
    name:'liveList2',
    components:{
        LiveList
    }
}
</script>
<style lang="stylus" scoped>
.liveList
    width 1200px
    margin 0 auto
    /deep/.live-list .tab .text
        margin-top 24px
</style>