
import { renderDom } from "./emojiRender";
const duration = 15;
const fontSize = 14
const lineHeight = 50
// const colors = [
//       "#FFD96F",  // 黄
//       "#FF5956",  // 红
//       "#24F5EF",  // 蓝
// ]

export function barrage(el) {

      let textlist = [];
      setInterval(() => {
            if (textlist.length === 0) {
                  return;
            }
            let line = Math.floor(Math.random() * 3);
            for (let i = el.children.length - 1; i >= 0; i--) {
                  let lineBox = el.children[i];
                  if (lineBox.line === line) {
                        if ((lineBox.offsetLeft + lineBox.offsetWidth) > el.offsetWidth ) {
                              return
                        }
                  }
            }

            let textObj = textlist.shift();
            let elWidth = el.offsetWidth;

            const img = document.createElement("img");
            img.src = textObj.userHeadPortrait || 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/userL.png';
            img.style.width = '32px'
            img.style.height = '32px'
            img.style.marginRight = '5px'
            img.style.borderRadius = '50%'
            const p = document.createElement("p");
            let render = renderDom(textObj)

            render.length && render.forEach(item => {
              if(item.name === 'text'){
                const span = document.createElement("span");
                span.textContent = item.text;
                span.style.display = 'inline-block';
                span.style.verticalAlign = 'middle';
                p.append(span)
              }else {
                const img2 = document.createElement("img");
                img2.src = item.src;
                img2.style.width = '24px';
                img2.style.height = '24px';
                img2.style.borderRadius = '50%';
                img2.style.verticalAlign = 'middle';
                p.append(img2)
              }
            })

            // p.textContent = temp;

            const div = document.createElement('div');
            div.line = line;
            div.style.position = "absolute";
            div.style.display = 'flex';


            div.style.top = line * lineHeight + 'px';
            div.style.color = '#fff'
            div.style.left = elWidth + "px";
            div.style.fontSize = fontSize + 'px';
            div.style.background = 'rgba(0,0,0,.7)'
            div.style.padding = '6px 12px 6px 6px'
            div.style.borderRadius = '30px'
            div.style.height = '30px'
            div.style.lineHeight = '30px'
            // div.style.fontWeight = 'bold';
            // div.style.textShadow = 'rgba(0, 0, 0, 0.24) 0px 1px 2px';
            div.style.wordBreak = 'break-all';
            div.style.whiteSpace = 'nowrap';
            div.style.transition = `all ${duration}s linear 0s`;

            div.append(img);
            div.append(p);
            el.appendChild(div);
            setTimeout(() => {
                  div.style.left = -div.offsetWidth + 'px'
                  setTimeout(() => {
                        div.parentNode && div.parentNode.removeChild(div);
                  }, (duration) * 1000);
            }, 100);
      }, 100);
      return {
            emit(text) {
                  textlist.push(text);
            },
      }
}
