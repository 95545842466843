<template>
  <div id="menu-speaker"></div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      speakerMenu: null
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {
    // 创建扬声器菜单
    this.speakerMenu = BJY.SpeakerMenu.create({
      element: $("#menu-speaker"),
      canAdjustVolume: true,
      maxVolume: 100,
    });
  },
  beforeDestroy() {
    this.speakerMenu && this.speakerMenu.destroy()
  },
};
</script>

<style lang="less" scoped>
//@import url(); 引入公共css类
</style>
