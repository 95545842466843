<template>
  <div id="answer-racer"></div>
</template>

<script>
export default {
    name: 'AnswerRacerStudent',
    data() {
      return {
        racerStudent: null
      }
    },
    mounted() {
      this.racerStudent = BJY.AnswerRacerStudent.create({
        element: $('#answer-racer')
      });
    },
    beforeDestroy() {
        this.racerStudent && this.racerStudent.destroy()
    },
}
</script>

<style>

</style>