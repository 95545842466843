import TRTC from 'trtc-js-sdk';
import { tryTimes } from './trytimes';

export function client(userId, sdkAppId, userSig) {

      // let whiteboardCache = ''

      const client = TRTC.createClient({
            mode: 'live',
            sdkAppId,
            userId,
            userSig,
      });

      client.on('error', (error) => {
            console.error('client error observed: ',  error);
            alert('系统错误, 请重新进入房间 ' + error.message);
            history.back();
      });

      // client.on('client-banned', async error => {
      //       console.error('client-banned observed: ' + error);
      //       try {
      //             await vm.hangUp();
      //       } catch (error) {
      //             console.error('被踢挂断电话');
      //       }
      //       try {
      //             await handler.leaveRoom();
      //       } catch (error) {
      //             console.error('被踢离开房间');
      //       }

      //       try {
      //             vm._group && await vm._group.quitGroup();
      //       } catch (error) {
      //             console.error('被踢退出群');
      //       }

      //       try {
      //             await vm._imClient.logout();
      //       } catch (error) {
      //             console.error('被踢登出');
      //       }

      //       alert('多端登录被踢');
      //       history.back();
      // });

      const remoteStreamList = {};
      let localStream;
      let streamInit = '';
      var errorCodeStatus = false;  // 当本地音频连接出现0x4043错误码为true   否则为false
      var onlineState = true; // 讲师端网络连接状态是否异常  true为正常  false为异常
      const handler = {
            startListen(cameraEl, screenEl, audioEl, whiteboardEl) {
                  client.on('stream-added', (event) => {
                        const remoteStream = event.stream
                        streamInit = event.stream
                        const remoteUserId = remoteStream.getUserId();
                        if (userId === remoteUserId) { // 取消来自自身的音频流
                              client.unsubscribe(remoteStream);
                        } else {
                              remoteStreamList[remoteUserId] = remoteStream;
                              client.subscribe(remoteStream);
                        }
                        // 监听连接状态
                        remoteStream.on('connection-state-changed', (event) => {
                              onlineState = false
                              event.state == 'CONNECTED' && (onlineState = true)
                              console.log(`prevState: ${event.prevState}, state: ${event.state}`);
                            });
                  });

                  client.on('stream-subscribed', (event) => {
                    this.streamSubscribed(event, cameraEl, screenEl, audioEl, whiteboardEl)
                  });

                  client.on('stream-removed', (event) => {
                        const remoteStream = event.stream;
                        remoteStream.stop();
                        const userType = remoteStream.getUserId().split('-')[4];
                        console.log('=====关闭屏幕共享=====', userType)
                        if (userType === '1') {
                              this.onScreenFinish && this.onScreenFinish();
                        }
                        if (userType === '0') {
                              this.onTeacherFinish && this.onTeacherFinish();
                        }
                        delete remoteStreamList[userType];
                  }),
                  client.on('network-quality', event => {
                        console.log(`network-quality, uplinkNetworkQuality:${event.uplinkNetworkQuality}, downlinkNetworkQuality: ${event.downlinkNetworkQuality}`)
                        // 自 v4.10.3 支持获取上、下行的 RTT 及丢包率
                        console.log(`uplink rtt:${event.uplinkRTT} loss:${event.uplinkLoss}`)
                        console.log(`downlink rtt:${event.downlinkRTT} loss:${event.downlinkLoss}`)
                     })

                  client.on('mute-video', event => {
                        const userType = event.userId.split('-')[4];
                        // alert('mute-video  ' + userId);
                        if (userType === '0') {
                              this.onVideoClose && this.onVideoClose();
                        }
                  });

                  client.on('unmute-video', event => {
                        const userType = event.userId.split('-')[4];

                        // alert('unmute-video  ' + userId);
                        if (userType === '0') {
                              this.onVideoOpen && this.onVideoOpen();
                        }
                        console.log(onlineState)
                  });
            },
            // getErrorFn :()=>({errorCodeStatus, onlineState}), // 挂载全局方法   出现错误状态码0x4043  回传true状态
            getErrorFn :()=>errorCodeStatus, // 挂载全局方法   出现错误状态码0x4043  回传true状态

            joinRoom(roomId) {
                  roomId = parseInt(roomId);

                  return new Promise((resolve, reject) => {
                        tryTimes((retry) => {
                              client.join({ roomId, role: 'audience' }).then(() => {
                                    this.onJoined && this.onJoined();
                                    resolve();
                              }).catch((error) => {
                                    if (!retry()) {
                                          console.log('进房失败 ', error);
                                          alert('进房失败 请刷新一下网页再试一次');
                                          reject(error);
                                    }
                              });
                        });
                  });
            },

            leaveRoom() {
                  return new Promise((resolve, reject) => {
                        tryTimes((retry) => {
                              client.leave().then(() => {
                                    this.onLeaveRoom && this.onLeaveRoom();
                                    resolve();
                              }).catch(error => {
                                    if (!retry()) {
                                          console.error('leaving room failed: ' + error);
                                          reject(error);
                                    }
                              });
                        }, 100);
                  });
            },

            forceCloseStream(remoteUserId) { // 强制关闭流
                  const stream = remoteStreamList[remoteUserId];
                  if (stream) {
                        stream.stop();
                        stream.close();
                        delete remoteStreamList[remoteUserId];
                  }
            },
            switchAnchor( successCallback, errorCallback ) {
                  localStream = TRTC.createStream({ audio: true, video: false });
                  client.switchRole('anchor').then(() => {
                        localStream.initialize().then(() => {
                              client.publish(localStream).then(() => {
                                    console.log('-----本地流发布成功----');
                                    successCallback && successCallback();
                              }).catch((error) => {
                                    console.error('本地流发布失败 ' + error);
                                    errorCallback && errorCallback(error)
                              });
                        }).catch((error) => {
                              console.error('初始化本地流失败 ' + error);
                              let message = '麦克风无法被使用'
                              switch (error.name) {
                                    case 'NotFoundError': {
                                          message = '电脑没有找到麦克风设备'
                                          break;
                                    }
                                    case 'NotAllowedError': {
                                          message = '拒绝了访问麦克风设备';
                                          break;
                                    }
                                    case 'NotReadableError': {
                                          message = '暂时无法访问摄像头/麦克风';
                                          break;
                                    }
                                    case 'OverConstrainedError': {
                                          message = 'cameraId/microphoneId 传值错误';
                                          break;
                                    }
                                    case 'AbortError': {
                                          message = '麦克风无法被使用';
                                          break;
                                    }
                                    default: {
                                          message = '麦克风无法被使用';
                                          break;
                                    }
                              }
                              errorCallback(new Error(message));
                        });
                  }).catch((error) => {
                        errorCallback(error);
                  });

                  return function(successCallback) {
                        localStream.close();
                        client.unpublish(localStream).then(() => {
                              console.log('本地流取消成功');
                              client.switchRole('audience').then(() => {
                                    console.log('======successCallback', successCallback)
                                    successCallback && successCallback()
                              }).catch(() => {
                                    successCallback && successCallback()
                              });
                        }).catch((error) => {
                              console.error('-----本地流取消失败 -----' + error);
                              client.switchRole('audience').then(() => {
                                    successCallback && successCallback()
                              }).catch(() => {
                                    successCallback && successCallback()
                              });
                        });
                  };
            },
            muteAudio() {
                localStream.muteAudio()
            },

            unmuteAudio() {
                localStream.unmuteAudio()
            },
            streamSubscribed(event, cameraEl, screenEl, audioEl, whiteboardEl) {
                const remoteStream = event.stream;
                console.log('hanmeng==== streamSubscribed', remoteStream);
                let el;
                const userType = remoteStream.getUserId().split('-')[4];
                const remoteUserId = remoteStream.getUserId();
                if (userId === remoteUserId) { // 取消来自自身的音频流
                      client.unsubscribe(remoteStream);
                }
                // userType 0 主播摄像头 1 屏幕共享流 2 学员连麦
                if (userType === '1') {
                    el = screenEl; // 屏幕共享盒子
                    this.onScreening && this.onScreening();
                } else if (userType === '0') {
                      el = cameraEl; // 老师摄像头盒子
                } else if (userType === '2') {
                      el = audioEl;
                } else if (userType === '3') { // 白板
                      el = whiteboardEl;
                }

                remoteStream.on('player-state-changed', event => {
                      if (event.state === 'PAUSED') {
                            remoteStream.resume();
                      }
                });


                const play = () => {
                      el.innerHTML = '';
                      remoteStream.play(el, { objectFit: 'contain' }).then(() => {
                            if (userType === '0') {
                                  this.onLiving && this.onLiving();
                            } else if (userType === '1') {
                                  this.onScreening && this.onScreening();
                                  // console.log('======开启屏幕共享======', this.onScreening)
                            }
                      }).catch((error) => {
                            const errorCode = error.getCode();
                            if (errorCode === 0x4043) {
                                  console.log('=====>error', error)
                                  const resume = () => {
                                        document.removeEventListener('click', resume);
                                        cameraEl.onclick();
                                  }
                                  errorCodeStatus = true
                                  document.addEventListener('click', resume);
                                  // alert('没有听到声音点击一下页面, 再试一试');
                            }
                      })
                };
                play()

                cameraEl.onclick = () => {
                      let video = cameraEl.getElementsByTagName('video')[0];
                      let audio = cameraEl.getElementsByTagName('audio')[0];
                      video && video.play().catch((error) => {
                            console.error(error);
                      });
                      audio && (audio.muted = false);
                      console.log('=======audio.muted', audio.muted)
                      streamInit.resume() // 初始化音频  重新开启本地音频连接
                }
          }
      }

      return handler;
}
