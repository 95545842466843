/* eslint-disable */
import TIM from 'tim-js-sdk';
import COS from "cos-js-sdk-v5";

import { openTipDialog } from '../page/dialogs/TipDialog';

import { tryTimes } from './trytimes'

export function imclient(SDKAppID, userId, vm) {
      let options = {
            SDKAppID: SDKAppID
      };
      let tim = TIM.create(options);
      tim.setLogLevel(0);
      tim.registerPlugin({'cos-js-sdk': COS});

      let receivedListener;
      let receivedGroupListener;

      tim.on(TIM.EVENT.MESSAGE_RECEIVED, function(event) {
            const msgs = event.data;
            for (let msg of msgs) {
                  console.log('接受消息 conversationType', msg.conversationType, 'type', msg.type, 'payload', msg.payload)
                  switch (msg.conversationType) {
                        case TIM.TYPES.CONV_GROUP: { // 群聊
                              switch (msg.type) {
                                    case TIM.TYPES.MSG_TEXT: {
                                          const msgObj =  JSON.parse(msg.payload.text);
                                          if (userId === msgObj.to) {
                                                receivedListener && receivedListener(msgObj)
                                          }
                                          receivedGroupListener && receivedGroupListener(msgObj);
                                          break;
                                    }
                              }
                              break;
                        }
                        case TIM.TYPES.CONV_C2C: { // 单聊
                            switch (msg.type) {
                                case TIM.TYPES.MSG_TEXT: {
                                    const msgObj =  JSON.parse(msg.payload.text);
                                    console.log(msgObj,'单聊消息')
                                    receivedListener && receivedListener(msgObj);
                                    break;
                                }
                            }
                            break;
                        }
                  }
            }
      });

      let loginReadyResolve = null;
      let rejoinGroup = null;


      let logined = false;
      let sdkReady = false;

      tim.on(TIM.EVENT.SDK_READY, () => {
            console.log('----------ready----------');
            sdkReady = true;
            if (logined && sdkReady) {
                  loginReadyResolve();
                  loginReadyResolve = null;
            }
      });
      let delayLoginTimer;
      tim.on(TIM.EVENT.SDK_NOT_READY, async (event) => {
            console.log('----------not ready----------', event);
            sdkReady = false;
            clearTimeout(delayLoginTimer);
            delayLoginTimer = setTimeout(async () => {
                  await reloginIfNeed();
            }, 1000);
      });

      tim.on(TIM.EVENT.KICKED_OUT, async (event) => {
            console.log(event,'9999999live被踢出直播++++');
            clearTimeout(delayLoginTimer);

            let msg = '当前账号正在其他设备观看直播';
            switch (event.data.type) {
                  case TIM.TYPES.KICKED_OUT_MULT_ACCOUNT:
                        msg = '当前账号正在其他设备观看直播';
                        break;
                  case TIM.TYPES.KICKED_OUT_MULT_DEVICE:
                        msg = '当前账号正在其他设备观看直播';
                        break;
                  case TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED:
                        msg = '登录过期, 重新进入房间';
                        break;
                  default:
                        msg = '当前账号正在其他设备观看直播';
                        break;
            }

            try {
                  vm._hangUp && vm._hangUp(() => {
                        vm.formTeacherLianmaiFlag = false;
                        vm.offing = false;
                        delete vm._hangUp;
                  }, () => {
                        vm.offing = false;
                  });
            } catch (error) {
                  console.error('被踢挂断电话');
            }
            try {
                  vm._receiver.leaveRoom();
            } catch (error) {
                  console.error('被踢离开房间');
            }

            sessionStorage.setItem('kicked', 'true');
            openTipDialog({
                  title: '温馨提示',
                  content: msg,
                  okbutton: '确定退出'
            }, vm).afterClosed().then(() => {
                  vm.$router.push('/learning')
            });
      });

      let reloginIfNeed;

      return {

            loginAndReady(userID, userSig) {
                  reloginIfNeed = async () => {
                        if (!sdkReady) {
                              // try {
                              //       await this.loginAndReady(userID, userSig)
                              //       console.log('----------------再次登录---------------')
                              // } catch (error) {
                              //       console.error('----------------再次登录---------------', error);
                              // }
                              // try {
                              //       rejoinGroup && await rejoinGroup();
                              //       console.log('-----------------re join group, 再次入群-------------')
                              // } catch (error) {
                              //       console.error('-----------------re join group, 再次入群-------------', error);
                              // }
                        }
                  };

                  return new Promise((resolve, reject) => {
                        loginReadyResolve = resolve;
                        tryTimes((retry) => {
                              tim.login( {userID, userSig} ).then(() => {
                                    logined = true;
                                    if (logined && sdkReady) {
                                          resolve();
                                    }
                              }).catch((error) => {
                                    if (!retry()) {
                                          reject(error)
                                    }
                              });
                        });
                  });
            },

            async logout() {
                  await reloginIfNeed();
                  return new Promise((resolve, reject) => {
                        tim.logout().then(() => {
                              logined = false;
                              resolve();
                        }).catch((error) => {
                              console.error(error);
                              reject(error);
                        });
                  });
            },

            async joinGroup(groupID) {
                  await reloginIfNeed();
                  rejoinGroup = async () => {
                        const imResponse = await tim.joinGroup({ groupID, type: TIM.TYPES.GRP_AVCHATROOM });
                        if (imResponse.data.status === TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL) {
                              throw Error('加群失败: 等待管理员同意');
                        }
                  }

                  await rejoinGroup();

                  return {
                        async sendMessageForGroup(cmdObject) {
                              await reloginIfNeed();
                              return await tim.sendMessage(tim.createTextMessage({
                                    to: `${groupID}`,
                                    conversationType: TIM.TYPES.CONV_GROUP,
                                    priority: TIM.TYPES.MSG_PRIORITY_HIGH,
                                    payload: {
                                          text: JSON.stringify(cmdObject)
                                    }
                              }), {
                                    onlineUserOnly: true
                              });
                        },

                        async postMessageForGroup(cmdObject) {
                              await reloginIfNeed();
                              return await tim.sendMessage(tim.createTextMessage({
                                    to: `${groupID}`,
                                    conversationType: TIM.TYPES.CONV_GROUP,
                                    priority: TIM.TYPES.MSG_PRIORITY_HIGH,
                                    payload: {
                                          text: JSON.stringify(cmdObject)
                                    }
                              }), {
                                    onlineUserOnly: true
                              });
                        },
                        quitGroup() {
                              return new Promise((resolve, reject) => {
                                    tim.quitGroup(groupID).then(() => {
                                          resolve();
                                    }).catch((error) => {
                                          reject(error);
                                    });
                              });
                        }
                  };
            },

            async call(toUserId, text) {
                  await reloginIfNeed();
                  return new Promise((resolve, reject) => {
                        if (logined && sdkReady) {
                              tim.sendMessage(tim.createTextMessage({
                                    to: toUserId,
                                    conversationType: TIM.TYPES.CONV_C2C,
                                    payload: {
                                          text
                                    }
                              })).then((imResponse) => {
                                    resolve(imResponse)
                              }).catch((error) => {
                                    this.onError && this.onError(error);
                                    reject(error)
                              });
                        } else {
                              alert('请登录');
                              reject('请登录')
                        }
                  });
            },

            onWaiting(listener) {
                  receivedListener = listener;
            },

            onWaitingGroup(listener) {
                  receivedGroupListener = listener;
            }
      }
}
